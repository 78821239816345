@use '../../Root/variables' as v;

.choose-bond-view{
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  width: 89%;
  max-width: 833px;
  margin: auto;

  .choose-bond-view-card{
    border-radius: 10px;
    padding: 20px;
    width: 100%;

    position: relative;
    z-index: 2;
    
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      background: v.$card-background;
      backdrop-filter: blur(40px);
    }
  
    /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background: #181B1C;
    }

    .choose-bond-view-card-header{
      padding: 8px;

      .choose-bond-view-card-title{
        font-weight: bold;
        font-size: 20px;
        color: white;
        margin-bottom: 4px;
      }

      .choose-bond-view-card-subtitle{
        position: relative;
        font-size: 12px;
        color: white;
        
        span {
          color: v.$textSubtle;
        }
      }
    }

    .choose-bond-view-card-metrics{
      margin: 30px 0;
      
      .choose-bond-view-card-metrics-title{
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: v.$textSubtle;
      }
    
      .choose-bond-view-card-metrics-value{
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: v.$text;
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }

    .choose-bond-view-card-action-area{
      width: 100%;
      margin-top: 25px;

      .choose-bond-view-card-action-stage-btns-wrap{
        display: flex;
        align-items: center;
        justify-content: center;

        .choose-bond-view-card-action-stage-btn{
          margin: 0px 30px;
          padding: 6px;
          cursor: pointer;
          transition: all .4s ease;
          
          p{
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            color: v.$primaryDark;
          }
        }

        .choose-bond-view-card-action-stage-btn.active{
          p{
            color: v.$primaryBright;
          }

          border-bottom: 1px solid v.$primary;
        }
      }
    }

    .choose-bond-view-card-table{
      .choose-bond-view-card-table-title{
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: v.$textSubtle;
      }
    }
  }

  .choose-bond-view-card-container{
    margin-top: 16px;
    width: 100%;

    .bond-data-card {
      margin: auto !important;
      background: v.$card-background;
      backdrop-filter: blur(40px);
      border-radius: 10px;
      padding: 20px;
      width: 100%;

      .bond-pair {
        display: flex !important;
        align-items: center;
        justify-content: left;
        margin-bottom: 15px;
        .MuiBox-root {
          margin-right: 10px;
        }
        .bond-name {
          svg {
            margin-left: 5px;
            vertical-align: middle;
            font-size: 15px;
          }
        }
      }
      .data-row {
        margin-bottom: 13px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin: 12px 0;
  
        .bond-price {
          display: flex;
          align-items: center;
  
          svg {
            margin-right: 5px;
          }
        }
      }
      .bond-link {
        margin-top: 10px;
        text-align: center;
        a {
          width: 96%;
        }
      }
    }
  }

  .bond-name-container{
    display: flex;
    justify-content: center;
    color: v.$text;
    font-size: 14px;

    .bond-name-title{
      font-weight: 600;
    }
    .bond-name-subtitle{
      font-weight: normal;
    }
  }

  .bond-table-btn-container{
    display: flex;
    gap: 5px;

    .bond-table-btn-subcontainer{
      flex: 1 1 0;
      
      .bond-table-btn{
        background: v.$primaryDark;
        border: 1px solid #767676;
        border-radius: 15px;
        padding: 6px 15px;
        text-align: center;
    
        &:hover{
          border: 1px solid rgba(255, 255, 255, 0.6);
        }
      
        p{
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #FFFFFF;
        }
      }

      .bond-table-btn-disable{
        background: transparent;
        border: 1px solid #767676;
        border-radius: 15px;
        padding: 6px 15px;
        text-align: center;
        pointer-events: none;
    
        &:hover{
          border: 1px solid rgba(255, 255, 255, 0.6);
        }
      
        p{
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #FFFFFF;
        }
      }
    }
  }

  .MuiTable-root {
    margin: 10px 0px;
  }

  .MuiTableRow-head {
    .MuiTableCell-alignLeft {
      padding-left: 20px;
    }
  }

  .MuiTableCell-root {
    p {
      margin: 0 !important;
      display: flex !important;
      align-items: center;
      justify-content: space-evenly;
      white-space: nowrap;
      img {
        margin-right: 3px;
      }
    }
    &.MuiTableCell-alignLeft {
      display: flex;
    }

    border-bottom: none;
  }

  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-alignLeft {
    justify-content: space-between;
    div.ohm-pairs {
      width: 33%;
      vertical-align: middle !important;
      align-items: center !important;
      justify-content: center !important;
      margin-bottom: 0 !important;
      .ohm-pairs div.bond-name p,
      .ohm-pairs svg {
        align-self: center !important;
        line-height: 100% !important;
        font-weight: bold !important;
        margin-bottom: 0em !important;
        padding: 0 !important;
        font-family: inherit;
        vertical-align: text-top !important;
      }
      .ohm-logo-bg {
        margin-left: 0;
        border-radius: 20px;
        height: 40px;
        width: 40px;
      }
    }
    div.bond-name {
      margin-left: 8px !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      align-items: flex-start;
      p {
        margin-bottom: unset !important;
        line-height: 140% !important;
      }
      svg {
        margin-left: 3px !important;
        font-size: 1.2em !important;
      }
    }
  }
}

.subtleText{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: v.$textSubtle;
}
.text{
  font-size: 14px;
  line-height: 17px;
  color: v.$text;
}

@media screen and (max-width: 1240px) {
  .choose-bond-view{
    width: 100%;
  }
}

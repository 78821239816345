@use '../../../Root/variables' as v;

.menu-root{
  .menu-btn{
    padding: 9px 20px;
    background: v.$hover-btn-background;
    box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
    border-radius: 15px;
    cursor: pointer;
    margin-right: 20px;

    p{
      font-weight: 600;
      font-size: 18px;
      color: #FFFFFF;
    }
  }
}

.menu-popper{
  margin-top: 10px;
  padding: 10px 0 10px 0;
  width: 250px;
  text-align: left;
  border: 1px solid v.$hover-btn-background;

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    background: v.$card-background;
    backdrop-filter: blur(100px);
  }

  /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background: #181B1C;
  }

  border-radius: 10px;
  overflow: hidden;
  z-index: 10;

  .tooltip{
    display: flex;
    flex-direction: column;
    padding: 0 5px 0 5px;

    .tooltip-item{
      text-decoration: none;
      padding: 13px 20px;
      border-radius: 5px;
      cursor: pointer;

      &:hover{
          background: rgba(255,255,255,0.1);
      }

      p{
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #FFFFFF;
          // text-align: center;
      }
    }

    .tooltip-icon{
      text-decoration: none;
      padding: 13px 5px;
      border-radius: 5px;
      cursor: pointer;
      text-align: center;

      &:hover{
        background: rgba(255,255,255,0.1);
      }

      p{
        line-height: 20px;
        color: #FFFFFF;
        text-align: center;
        font-weight: bold;
      }

      .token-icon{
        height: 30px;
        width: 30px;
      }
    }

    .add-tokens{
      display: flex;
      flex-direction: column;

      .add-tokens-title{
        font-style: normal;
        font-size: small;
        color: v.$textSubtle;
        padding: 13px 20px 0 20px;
        // text-align: center;

        margin: 5px 0px;
      }
    }

    .divider{
      height: 1px;
      background: v.$divider;
      border: none;
    }
  }
}

.connect-button{
    background: v.$hover-btn-background;
    box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
    border-radius: 10px;
    padding: 9px 20px;
    cursor: pointer;

    display: flex;
    align-items: center;

    .connect-button-progress{
      margin-left: 10px;
      margin-top: 4px;
    }
  
    p{
      font-weight: 600;
      font-size: 18px;
      color: #FFFFFF;
    }
  }
@use '../../Root/variables' as v;

.dashboard-view {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  width: 89%;
  max-width: 833px;
  margin: auto;

  .dashboard-infos-wrap{
    background: v.$card-background;
    border-radius: 20px;
    padding: 30px;

    .dashboard-section{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      position: relative;
      z-index: 2;
      border-radius: 10px;
    }

    .dashboard-card{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      position: relative;
      z-index: 2;
      
      // @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      //   background: v.$card-background;
      //   backdrop-filter: blur(100px);
      // }
    
      // /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
      // @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      //   background: #181B1C;
      // }

      border-radius: 10px;
      padding: 13px 0;
    }
  }
}

.section-title {
  font-weight: 500;
  font-size: 20px;
  color: v.$textSubtle;
  text-align: center;
}

.section-subtitle {
  font-weight: bold;
  font-size: 24px;
  color: v.$text;
  text-align: center;
}

.card-title {
  font-weight: 500;
  font-size: 16px;
  // color: rgba(255, 255, 255, 0.6);
  color: v.$textSubtle;
}

.card-value {
  font-weight: bold;
  font-size: 24px;
  color: v.$text;
}
  
.card-subvalue {
  font-size: 14px;
  color: v.$textSubtle;
}
@use '../../../Root/variables' as v;

.dapp-sidebar {
  height: 100vh;
  min-width: 280px;
  display: flex;
  flex-direction: column;
  background: v.$backgroundGradient;

  .branding-header {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 20px 0 63px 0;

    .wallet-link {
      margin: 10px;
  
      p{
        color: #FFFFFF;
      }
    }

    a {
      .title {
        font-weight: 600;
        font-size: 24px;
        color: #FFFFFF;
      }

      img {
        height: 75px;
        width: 75px;
      }
    }
    
    a:hover { 
      text-decoration: none; 
    }
  }

  .dapp-menu-links{
    display: flex;
    align-items: center;

    .dapp-nav {
      display: flex;
      flex-flow: column;

      a {
        margin-top: 15px;
        margin-left: 20px;
        align-items: center;
        margin-bottom: 15px;

        img, svg {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          vertical-align: -4px;
        }
      }

      .button-dapp-menu{
        .dapp-menu-item{
          display: flex;
          flex-direction: row;
          align-items: center;
        
          p{
            font-weight: 600;
            font-size: 16px;
            color: #FFFFFF;
          }
        }

        .dapp-menu-item-nested{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        
          p{
            font-weight: 600;
            font-size: 16px;
            color: #FFFFFF;
          }
        }
      }

      .button-dapp-menu.active {
        .dapp-menu-item{
          p{
            text-decoration: underline;
          }
        }
      }

      .bond-discounts {
        text-align: left;
        padding-left: 54px;
      
        a{
          margin-left: 0;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      
        p{
          font-size: 14px;
          line-height: 17px;
          color: rgba(255, 255, 255, 0.8);
        }
        
        .bond {
          display: block;
          // padding: unset;
          margin-bottom: 0 0 1px 0;
          text-decoration: none !important;
          .bond-pair-roi {
            float: right;
            margin-left: 33px;
          }
        }
      }
    }
  }

  .dapp-menu-doc-link{
    margin-top: auto;
    
    a {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        vertical-align: middle;
      }

      p{
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
      }
    }
  }

  .social-row {
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    padding: 1.3rem 1.3rem 0.5rem 1.3rem;
    a {
      &:hover {
        transform: scale(1.1);
      }
      img {
        height: 20px;
        width: 20px;
      }
    }
  }

  .quote {
    padding-bottom: 1.3rem;
    font-size: small;
    font-weight: bold;
    text-align: center;
    color: transparent;
    margin-bottom: 4px;
    background: v.$textGradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .mgmi {
    display: inline-block;
  }
}

.MuiDrawer-root{
  .MuiPaper-root{
    
    z-index: 7;

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      background: v.$card-background;
      backdrop-filter: blur(40px);
    }
  
    /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background: #181B1C;
    }
  }
}
@use '../../Root/variables' as v;

.rebase-timer {
  position: relative;

  p{
    // font-weight: 600;
    font-size: 12px;
    color: white;
    
    span {
      color: v.$textSubtle;
    }
  }
}
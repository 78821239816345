@use "../../Root/variables" as v;

.home-view {
    width: 89%;
    max-width: 833px;
    margin: auto;

    .home-card-clear {
        position: relative;
        z-index: 2;
        border-radius: 10px;
        padding: 20px;
        width: 100%;
        color: #ffffff;

        .home-card-grid {
            margin: 0;
            width: 100%;
        }

        .home-card-header {
            word-wrap: break-word;

            .home-card-header-title {
                font-weight: bold;
                font-size: 60px;
                color: #ffffff;
                margin-bottom: 4px;
            }

            .home-card-header-subtitle {
                font-weight: bold;
                font-size: 30px;
                color: #ffffff;
            }
        }

        .home-card-metrics {
            display: flex;
            width: 100%;
            padding: 0;
            text-align: center;
            margin-top: 10px;

            .home-card-apy {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                overflow: hidden;
                height: 100%;

                .home-card-metrics-title {
                    font-style: bold;
                    font-weight: 600;
                    font-size: 30px;
                    margin-bottom: 2px;
                }
            }

            .bond-table-btn-subcontainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                height: 100%;

                .bond-table-btn {
                    background: v.$primaryDark;
                    border: 1px solid #767676;
                    border-radius: 15px;
                    padding: 10px 15px;
                    text-align: center;
                    height: 60px;
                    min-width: 300px;
                    max-width: 500px;

                    &:hover {
                        border: 1px solid rgba(255, 255, 255, 0.6);
                    }

                    p {
                        font-style: bold;
                        font-weight: 600;
                        font-size: 20px;
                        color: #ffffff;
                        padding: 5px;
                    }
                }
            }
        }

        .home-card-area {
            .home-card-area-wrap {
                margin: 50px 0;

                .home-card-area-title {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    color: white;
                    margin-bottom: 10px;
                }
            }
        }

        .social-container {
            margin-top: 150px;
            margin-bottom: 50px;
            display: flex;
            justify-content: center;
            text-align: center;
            column-gap: 40px;

            .social-subcontainer {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .social-icon {
                    border-radius: 50%;
                    height: 60px;
                    width: 60px;
                }

                p {
                    font-style: bold;
                    font-weight: 600;
                    font-size: 20px;
                    color: white;
                    margin-top: 5px;
                }
            }
        }
    }

    .home-card {
        position: relative;
        z-index: 2;
        border-radius: 10px;
        padding: 20px;
        width: 100%;
        margin-top: 50px;

        @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
            background: v.$card-background;
            backdrop-filter: blur(40px);
        }

        /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
        @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            background: #181b1c;
        }

        .home-card-grid {
            margin: 0;
            width: 100%;
        }

        .home-card-header {
            .home-card-header-title {
                font-weight: bold;
                font-size: 30px;
                color: #ffffff;
            }

            .home-card-header-subtitle {
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #ffffff;
            }
        }

        .home-card-metrics {
            display: flex;
            width: 100%;
            padding: 0;
            text-align: center;
            margin-top: 10px;
        }

        .home-card-area {
            .home-card-area-wrap {
                margin-top: 10px;
                margin-bottom: 30px;

                .home-card-area-title {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    color: white;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .home-view {
        .home-card {
            margin-bottom: 100px;

            .home-card-metrics {
                .home-card-apy {
                    align-items: flex-start;
                }

                .home-card {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }

            .home-card-area {
                .home-card-action-area {
                    .home-card-action-row {
                        flex-direction: column;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1240px) {
    .home-view {
        width: 100%;
    }
}

.loader-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: #ffffff;
    pointer-events: none;

    .splash {
        width: 50%;
        height: 50%;
    }

    .project-splash {
        height: 15%;
    }

    .loader-bar {
        margin-top: 10px;
        width: 25%;
    }
}

.MuiLinearProgress-root {
    .MuiLinearProgress-barColorPrimary {
        background-color: white;
    }
}

@use '../../Root/variables' as v;

.stake-view{
  width: 89%;
  max-width: 833px;
  margin: auto;

  .stake-card{
    position: relative;
    z-index: 2;
    
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      background: v.$card-background;
      backdrop-filter: blur(40px);
    }
  
    /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background: #181B1C;
    }

    border-radius: 10px;
    padding: 20px;
    width: 100%;

    .stake-card-grid{
      margin: 0;
      width: 100%;
    }

    .stake-card-header{
      .stake-card-header-title{
        width: 125px;
        font-weight: bold;
        font-size: 20px;
        color: white;
        // color: transparent;
        margin-bottom: 4px;
        // background: v.$textGradient;
        // background-clip: text;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
      }
    }

    .stake-card-metrics {
      display: flex;
      width: 100%;
      padding: 0;
      text-align: center;
      margin-top: 10px;
      
      .stake-card-apy,
      .stake-card-tvl,
      .stake-card-index {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        .stake-card-metrics-title{
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          color: v.$textSubtle;
          margin-bottom: 2px;
        }
        
        .stake-card-metrics-value{
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          color: v.$text;
        }
      }
    }

    .stake-card-area {
      .stake-card-wallet-notification{
        text-align: center;

        .stake-card-wallet-connect-btn{
          background: v.$primaryDark;
          box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
          border-radius: 10px;
          padding: 14px 58px;
          cursor: pointer;
          margin: auto;
          max-width: 280px;
          margin-top: 20px;
        
          p{
            font-weight: 600;
            font-size: 18px;
            color: #FFFFFF;
          }
        }

        .stake-card-wallet-desc-text{
          font-weight: 300;
          font-size: 14px;
          color: v.$textSubtle;
          margin-top: 10px;
          margin-bottom: 6px;
        }
      }

      .stake-card-action-area{
        width: 100%;
        margin-top: 25px;

        .stake-card-action-stage-btns-wrap{
          display: flex;
          align-items: center;
          justify-content: center;

          .stake-card-action-stage-btn{
            margin: 0px 30px;
            padding: 6px;
            cursor: pointer;
            transition: all .4s ease;
            
            p{
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              color: v.$primaryDark;
            }
          }

          .stake-card-action-stage-btn.active{

            p{
              color: v.$primaryBright;
            }

            border-bottom: 1px solid v.$primary;
          }
        }

        .stake-card-action-row{
          display: flex;
          align-items: center;
          margin-top: 20px;

          .stake-card-action-input{
            width: 100%;

            background: v.$input-background;
            // border: 1px solid v.$primaryDark;
            border-radius: 20px;
            outline: none;
            
            .MuiOutlinedInput-input{
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              color: v.$text;
              padding: 14px 20px;
            }
            
            .MuiOutlinedInput-notchedOutline{
              border-color: rgba(255, 255, 255, 0.2) !important;
            }
            
            .stake-card-action-input-btn{
              cursor: pointer;
                
              p{
                font-weight: 600;
                font-size: 18px;
                color: #FFFFFF;
              }
            }
          } 
          
          .stake-card-tab-panel{
            min-width: 215px;
            margin-left: 10px;

            .stake-card-tab-panel-btn{
              background: v.$primaryDark;
              box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
              border-radius: 15px;
              padding: 14px 0px;
              cursor: pointer;
              text-align: center;
          
              p{
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                color: #FFFFFF;
              }
            }
          }
        }

        .stake-card-action-help-text{
          margin-top: 10px;
          
          p{
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }

      .stake-user-data{
        justify-content: center;
        margin: auto;
        padding: 0 5px;
        margin-top: 30px;
        
        
      }
    }
  }
}

.data-row{
  display: flex;
  justify-content: space-between;
  margin: 12px 0px;

  .data-row-name{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: v.$textSubtle;
  }
  .data-row-value{
    font-size: 14px;
    line-height: 17px;
    color: v.$text;

    .tooltip{
      padding: 0;
      position: relative;
      top: -2px;

      .tooltip-icon{
        height: 12px;
        width: 12px;
        color: v.$textSubtle;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .stake-view{
    .stake-card{
      .stake-card-metrics{
        .stake-card-apy,
        .stake-card-tvl,
        .stake-card-index {
          align-items: flex-start;
        }

        .stake-card {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      .stake-card-area{
        .stake-card-action-area{
          .stake-card-action-row{
            flex-direction: column;
            .stake-card-tab-panel{
              margin-left: 0;
              margin-top: 20px;
              min-width: none;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1240px) {
  .stake-view{
    width: 100%;
  }
}
.view-base-root {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    // background: black;

    .cubes-top {
        opacity: 0.6;
        position: absolute;
        top: -120px;

        p {
            font-family: Courier;
            font-style: normal;
            font-weight: normal;
            font-size: 6px;
            color: #FFFFFF;
            white-space: pre-wrap;
            user-select: none;
        }
    }


    .cubes-bottom {
        opacity: 0.6;
        position: absolute;
        bottom: -256px;
        right: -140px;

        p {
            font-family: Courier;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            color: #FFFFFF;
            white-space: pre-wrap;
            user-select: none;
        }
    }
}
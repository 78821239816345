$primary: #ffaec8;
$primaryBright: white;
$primaryDark: #ffaec8;
$text: #fcf0f3;
$textSubtle: lightgrey;
$textGradient: linear-gradient(to right, white 0%, #ffaec8 50%);
$backgroundGradient: linear-gradient(to bottom, #ffaec8, #000000);
$input-background: #583944;
$hover-btn-background: rgba(39, 38, 44, 1);
$card-background: #fff1;
$divider: rgba(255, 255, 255, 0.3);
